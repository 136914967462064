import { Routes, Route } from 'react-router-dom';
import Header from "./pages/js files/header";
import Footer from "./pages/js files/footer";
import Body from "./pages/js files/body";
import Admin from "./pages/js files/admin";
import Login from "./pages/js files/login";
import AddItem from './pages/js files/addItem';
import "./app.css";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Body />} />
        <Route path="/addItem" element={<AddItem/>} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<Admin />}
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;