import React from 'react'
import "../css/footer.css"
import bkg_head from "../icons/bkg_head.jpeg";

function footer() {
  const headerStyle = {
    backgroundImage: `url(${bkg_head})`,
    backgroundRepeat: 'repeat',
    backgroundPosition: 'center',
  };

  return (
    <div className='footer' style={headerStyle}>
        <h1>
            THANK YOU FOR VISITING VASANTA BHAVAN!
        </h1>
        <div className='loggg'>
            If you are the admin, kindly login here.
        </div>
        <button className='futbutton'>
            <a href='/Login'>Login</a>
        </button>
    </div>
  )
}

export default footer