import React, { useState, useEffect } from 'react';
import '../css/body.css';
import { db } from './firebase'; 
import { useCollectionData } from "react-firebase-hooks/firestore";
import { collection, orderBy } from 'firebase/firestore';
import Childrenlist from './childrenlist';

function Body() {
  const query = collection(db, "MenuData");
  const [docs, loading] = useCollectionData(query);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    if (docs && docs.length > 0 && !selectedCategory) {
      setSelectedCategory(docs[0].name);
    }
  }, [docs, selectedCategory]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className='main-box'>
      <h1 className='invitation'>CATEGORIES</h1>

      {loading && "...loading"}

      <ul>
      {docs?.sort((a, b) => a.sortOrder - b.sortOrder).map((doc)=> (
          <button
            key={doc.id} 
            className={`headbutton ${selectedCategory === doc.name ? 'active' : ''}`}
            onClick={() => handleCategoryClick(doc.name)}
          >
            {doc.name}
          </button>
        ))}
      </ul>

      <div className="solid-line"></div> 
      <div className="selected-category">
        {selectedCategory && (
          <div>
            {selectedCategory}
            <div className="menu-line"></div> 
          </div>
        )}
      </div>

      {selectedCategory && (
        <Childrenlist path={`MenuData/${selectedCategory}/children`} />
      )}
     
      <div className="solid-line"></div> 

      <div className='disclaimer'>
        Net prices - Taxes and services included
      </div>
    </div>
  );
}

export default Body;
