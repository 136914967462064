import React from 'react';
import "../css/header.css";
import Logo from "../icons/logo.jpg";
import bkg_head from "../icons/bkg_head.jpeg";

function Header() {
  const headerStyle = {
    backgroundImage: `url(${bkg_head})`,
    backgroundRepeat: 'repeat',
    backgroundPosition: 'center',
  };

  const logoStyle = {
    boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.5)',
  };

  return (
    <div className='strip' style={headerStyle}>
      <img src={Logo} height={100} width={300} className='logoo' alt='logo' style={logoStyle}/>
    </div>
  );
}

export default Header;
