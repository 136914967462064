import { db } from './firebase'; 
import { useCollectionData } from "react-firebase-hooks/firestore"
import { collection, query } from 'firebase/firestore'; 
import NoImage from "../icons/no-img.png";
import "../css/child.css"



export default function Childrenlist({ path }) {
    const q = query(collection(db, path)); 
    const [docs, loading] = useCollectionData(q);

    return( 
        <ul className='prettylist'>
            {loading && "Loading..."}
            {docs && docs
             .slice() 
             ?.filter(doc => doc.visibility === true)
             .sort((a, b) => a.sortOrder - b.sortOrder) 
             .map((doc) => (
            <div className='menu-item'>
                <li key={doc.id}>

                <div className="item-image">
                  {doc.imageUrl ? (
                    <img src={doc.imageUrl} alt={doc.name} />
                  ) : (
                    <img src={NoImage} alt="No_Image" />
                  )}
                </div>
                <div className='item-details'>
                  <div className="item-name">{doc.name}</div>
                  <div className="item-price">Price: {doc.price} OMR</div>
                  <div className="item-description">{doc.description}</div>
                </div>
                </li>
            </div>
            ))}
        </ul>
    );
}
