import React, { useState, useEffect, useRef } from 'react';
import { collection, query, getDocs, getDoc, deleteDoc, doc, updateDoc, writeBatch, where } from 'firebase/firestore';
import { useCollectionData } from "react-firebase-hooks/firestore";
import { db, storage } from './firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import NoImage from "../icons/no-img.png";
import Visible from "../icons/visibke.png";
import Up from "../icons/up.png";
import Down from "../icons/down.png";
import Delete from "../icons/delete.png";
import AddItem from '../js files/addItem';
import "../css/child.css";

function Adminchildren({ path }) {
  const q = query(collection(db, path)); 
  const [docs, loading] = useCollectionData(q);
  const [menu, setMenu] = useState([]);
  const [items, setItems] = useState([]);
  const imageRef = useRef();
  const [file, setFile] = useState('');
  const [uploading, setUploading] = useState(false);
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [desc, setDesc] = useState(""); 
  const [newImageUrl, setNewImageUrl] = useState('');
  const [currentItemId, setCurrentItemId] = useState(null); // Track the current item being edited


  useEffect(() => {
    if (docs && docs.length > 0) {
      const sortedDocs = docs.sort((a, b) => a.sortOrder - b.sortOrder);
      setMenu(sortedDocs);
      setItems(sortedDocs);
    }
  }, [docs]);

  async function handleDelete(name) {
    try {
      const q = query(collection(db, path), where('name', '==', name));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
        console.log('Document deleted successfully:', doc.id);
      });

      setMenu(menu.filter(item => item.name !== name));
      updateSortOrders();
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  }

  async function handleSaveChanges() {
    try {
      updateSortOrders();
  
      const batch = [];
      menu.forEach(item => {
        const docRef = doc(db, path, item.id);
        batch.push(updateDoc(docRef, { 
          name: name || item.name,
          price: price || item.price,
          description: desc || item.description,
          sortOrder: item.sortOrder,
          imageUrl: file || item.imageUrl,
          visibility: item.visibility
        }));
      });
  
      await Promise.all(batch);
      console.log('Changes saved successfully!');
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  }
  
  const handleInputChange = (id, field, value) => {
    const updatedItems = items.map(item => {
      if (item.id === id) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setItems(updatedItems);
    setMenu(updatedItems);
  };

  const handleUpload = (e, id) => {
    const imgRef = ref(storage, `imgs/${id}`);
    setUploading(true);
  
    uploadBytes(imgRef, e.target.files[0]).then(data => {
      getDownloadURL(data.ref).then(url => {
        // Set the new image URL and file state
        setNewImageUrl(url);
        setFile(url);
        setUploading(false);
      });
    }).catch(error => {
      console.error('Error uploading image:', error);
      setUploading(false);
    });
  };

  const handleChangeClick = async (id) => {
    setCurrentItemId(id);
    imageRef.current.click();
    console.log(id)
  }

  const handleMoveUp = (id) => {
    const itemIndex = menu.findIndex(item => item.id === id);
    if (itemIndex > 0) {
      const temp = menu[itemIndex];
      menu[itemIndex] = menu[itemIndex - 1];
      menu[itemIndex - 1] = temp;
      setMenu([...menu]);
      updateSortOrders();
    }
  };
  
  const handleMoveDown = (id) => {
    const itemIndex = menu.findIndex(item => item.id === id);
    if (itemIndex < menu.length - 1) {
      const temp = menu[itemIndex];
      menu[itemIndex] = menu[itemIndex + 1];
      menu[itemIndex + 1] = temp;
      setMenu([...menu]);
      updateSortOrders();
    }
  };

  const updateSortOrders = async () => {
    try {
      const batch = writeBatch(db);
      menu.forEach((item, index) => {
        const docRef = doc(db, path, item.id);
        batch.update(docRef, { sortOrder: index + 1 });
      });
      await batch.commit();
      console.log('Sort order updated successfully.');
    } catch (error) {
      console.error('Error updating sort order:', error);
    }
  };

  async function handleVisibility(id) {
    try {
      const itemRef = doc(db, path, id);
      const itemSnapshot = await getDoc(itemRef);
      const currentVisibility = itemSnapshot.data().visibility;
      await updateDoc(itemRef, { visibility: !currentVisibility });
      console.log("Visibility toggled for item with id to:", !currentVisibility);
      updateVisibility();
    } catch (error) {
      console.error('Error toggling visibility:', error);
    }
  }

  async function updateVisibility() {
    try {
      const q = query(collection(db, path));
      const querySnapshot = await getDocs(q);
      const visibleItems = querySnapshot.docs.map(doc => doc.data());
      setMenu(visibleItems);
      setItems(visibleItems);
    } catch (error) {
      console.error('Error updating visibility:', error);
    }
  }

  const handleConfirmChange = async () => {
    if (newImageUrl && currentItemId) {
      try {
        const docRef = doc(db, path, currentItemId);
        await updateDoc(docRef, { imageUrl: newImageUrl });
        // Find the item in the menu and update its imageUrl
        const updatedMenu = menu.map(item => {
          if (item.id === currentItemId) {
            return { ...item, imageUrl: newImageUrl };
          }
          return item;
        });
        setMenu(updatedMenu);
        // Reset the new image URL state and file input
        setNewImageUrl('');
        setFile('');
        setCurrentItemId(null); // Reset the current item ID
      } catch (error) {
        console.error('Error confirming image change:', error);
      }
    }
  

  }

  return( 
    <div className='menu-item'>
      {loading && "Loading..."}
      {!loading && (!docs || docs.length === 0) ? (
        <div>
          <div className="empty-list-message">
            The list is empty. Add items?
          </div>
          <div>
            <AddItem path={path} />
          </div>
        </div>
      ) : (
        <ul className='prettylist'>
          {menu && menu.map((item) => (
            <div className='menu-item' key={item.id}>
              <li>
                <div>
                <div className="item-image">
                    {item.id === currentItemId && newImageUrl ? (  // Display the new image temporarily
                      <img src={newImageUrl} alt={item.name} />
                    ) : item.imageUrl ? (
                      <img src={item.imageUrl} alt={item.name} />
                    ) : (
                      <img src={NoImage} alt="No_Image" />
                    )}
                  </div>
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => handleUpload(e, item.id)}
                    ref={imageRef}
                  />
                  {item.id === currentItemId && newImageUrl ? (
                    // Display confirm button if new image URL exists
                    <button className='change-pic' onClick={handleConfirmChange}>Confirm Change</button>
                  ) : (
                    <button className='change-pic' onClick={() => handleChangeClick(item.id)}>Change Pic</button>
                  )}
                </div>
                <div className='item-details'>
                  <label>Name</label>
                  <input
                    type="text"
                    value={item.name}
                    onChange={(e) => handleInputChange(item.id, 'name', e.target.value)}
                  />
                  <label>Price</label>
                  <input
                    type="float"
                    value={item.price}
                    onChange={(e) => handleInputChange(item.id, 'price', e.target.value)}
                  />
                  <label>Description</label>
                  <input
                    type="text"
                    value={item.description}
                    onChange={(e) => handleInputChange(item.id, 'description', e.target.value)}
                  />
                </div>
                <div className='butlist'>
                  <button onClick={() => handleDelete(item.name)}>
                    <img src={Delete} alt='delete' />
                  </button>
                  <button onClick={() => handleMoveUp(item.id)}>
                    <img src={Up} alt="Up Arrow" />
                  </button>
                  <button onClick={() => handleMoveDown(item.id)}>
                    <img src={Down} alt="Down Arrow" />
                  </button>
                  <div>
                    <button onClick={() => handleVisibility(item.id)}>
                      <img src={Visible} alt="Visible" />
                    </button>
                    <div>
                      {item.visibility === false ? "Not Visible" : "Visible"}
                    </div>
                  </div>
                </div>
              </li>
            </div>
          ))}
          <button onClick={handleSaveChanges} className='headbutton'>Save Changes</button>
          <AddItem path={path} />
        </ul>
      )}
    </div>
  );
}

export default Adminchildren;
