import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD5qmgqTPx5FcVwQN8iGlFV8VL54-5u9tw",
  authDomain: "vboman-36739.firebaseapp.com",
  projectId: "vboman-36739",
  storageBucket: "vboman-36739.appspot.com",
  messagingSenderId: "746490854742",
  appId: "1:746490854742:web:57d5d3a4b97d3af701aaf7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);