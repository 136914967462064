import React, { useRef, useState, useEffect } from 'react';
import { setDoc, doc, collection, getDocs } from 'firebase/firestore';
import { db, storage } from './firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 } from 'uuid';
import '../css/addItem.css';

function AddItem({ path }) {
  const nameRef = useRef();
  const priceRef = useRef();
  const descriptionRef = useRef();
  const imageRef = useRef();
  const [file, setFile] = useState('');
  const [uploading, setUploading] = useState(false);
  const [previewURL, setPreviewURL] = useState('');
  const [sortOrder, setSortOrder] = useState(0);

  useEffect(() => {
    async function fetchSortOrder() {
      const querySnapshot = await getDocs(collection(db, path));
      setSortOrder(querySnapshot.size + 1); 
    }

    fetchSortOrder();
  }, [path]); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (uploading) {
      console.log('Image upload in progress, please wait...');
      return;
    }

    const newItem = {
      name: nameRef.current.value,
      price: parseFloat(priceRef.current.value),
      description: descriptionRef.current.value,
      id: v4(),
      imageUrl: file,
      sortOrder: sortOrder,
      visibility: true
    };

    try {
      const docRef = doc(db, path, newItem.id);
      await setDoc(docRef, newItem);
      console.log('Item added successfully:', newItem);
      e.target.reset();
      setFile('');
      setPreviewURL('');
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  const handleUpload = (e) => {
    const imgs = ref(storage, `imgs/${v4()}`);
    setUploading(true);

    uploadBytes(imgs, e.target.files[0]).then(data => {
      getDownloadURL(data.ref).then(val => {
        setFile(val);
        setPreviewURL(URL.createObjectURL(e.target.files[0]));
        setUploading(false); 
      });
    });
  };

  return (
    <div className='add-item-container'>
      <form onSubmit={handleSubmit} className='forma'>
        <label>ITEM NAME</label>
        <input type="text" placeholder='name of item' ref={nameRef}></input>

        <label>ITEM PRICE</label>
        <input type="float" placeholder='price of item' ref={priceRef}></input>

        <label>ITEM DESCRIPTION</label>
        <input type="text" placeholder='description of item' ref={descriptionRef}></input>

        <label>UPLOAD IMAGE</label>
        <input type="file" onChange={(e) => handleUpload(e)} />
        <div>Preview:</div>
        {previewURL && 
        <img src={previewURL} alt="Preview" className="image-preview" style={{ width: '100px', height: '100px' }} />}

        <button type="submit" className='headbutton' disabled={uploading}>
          {uploading ? 'Uploading...' : 'SUBMIT'}
        </button>
      </form>
    </div>
  );
}

export default AddItem;
