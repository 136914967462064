import React, { useRef } from 'react';
import { setDoc, getDocs, collection } from 'firebase/firestore';
import { db } from './firebase';
import { doc } from 'firebase/firestore';

function Addcat({ path }) {
    const name = useRef();

    async function handleSubmit(e) {
        e.preventDefault();

        const categoryRef = doc(db, path, name.current.value);
        const sortOrder = await getNextSortOrder(path);

        await setDoc(categoryRef, {
            name: name.current.value,
            sortOrder: sortOrder
        });
        e.target.reset();
    }

    async function getNextSortOrder(path) {
        // Fetch the categories and determine the next sort order
        // You can implement logic here based on existing categories in Firestore
        // For simplicity, let's assume you count the existing categories and add 1
        const querySnapshot = await getDocs(collection(db, path));
        const existingCategoriesCount = querySnapshot.size;
        return existingCategoriesCount + 1;
    }

    return (
        <li>
            <form onSubmit={handleSubmit}>
                <div>Add a new Category:</div>
                <input ref={name} placeholder='Category name' />
                <button type="submit" className='headbutton'>Add</button>
            </form>
        </li>
    );
}

export default Addcat;
