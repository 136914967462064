import React, { useState, useEffect } from 'react';
import '../css/admin.css';
import { db } from './firebase'; 
import { collection, doc, deleteDoc, writeBatch } from 'firebase/firestore'; 
import { useCollectionData } from "react-firebase-hooks/firestore";
import Addcat from "../js files/addcat";
import Adminchildren from '../js files/adminchildren';
import Up from "../icons/up.png";
import Down from "../icons/down.png";
import Delete from "../icons/delete.png";
import Preview from "../icons/preview.png";

function Admin() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [menu, setMenu] = useState([]);
  const query = collection(db, "MenuData");
  const [docs] = useCollectionData(query);

  useEffect(() => {
    if (docs) {
      // Sort categories based on sortOrder property
      const sortedMenu = docs.slice().sort((a, b) => a.sortOrder - b.sortOrder);
      setMenu(sortedMenu);
    }
  }, [docs]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleMoveUp = async (name) => {
    const categoryIndex = menu.findIndex(item => item.name === name);
    if (categoryIndex > 0) {
      const temp = menu[categoryIndex];
      menu[categoryIndex] = menu[categoryIndex - 1];
      menu[categoryIndex - 1] = temp;
      setMenu([...menu]);
    }
  };

  const handleMoveDown = async (name) => {
    const categoryIndex = menu.findIndex(item => item.name === name);
    if (categoryIndex < menu.length - 1) {
      const temp = menu[categoryIndex];
      menu[categoryIndex] = menu[categoryIndex + 1];
      menu[categoryIndex + 1] = temp;
      setMenu([...menu]);
    }
  };

  const updateSortOrders = async () => {
    try {
      const batch = writeBatch(db);
      menu.forEach((item, index) => {
        const docRef = doc(db, "MenuData", item.name);
        batch.update(docRef, { sortOrder: index + 1 });
      });
      await batch.commit();
      console.log('Sort order updated successfully.');
    } catch (error) {
      console.error('Error updating sort order:', error);
    }
  };

  const handleDelete = async (name) => {
    try {
      const category = doc(db, "MenuData", name)
      await deleteDoc(category)
      console.log("Item deleted successfully")
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  const handleSaveChanges = async () => {
    await updateSortOrders();
  };

  return (
    <>
      <h1 className='admin-header'>Admin Panel</h1>
      <div className="admin-container">
        <div className="left-box">
          <h2>Categories</h2>
          <ul>
            {menu.map((doc) => (
              <div
                key={doc.id}
                className="category-buttons-container"
              >
                <button onClick={() => handleMoveUp(doc.name)} className='delete-button'>
                  <img src={Up} alt="Up Arrow" />
                </button>
                <button onClick={() => handleMoveDown(doc.name)} className='delete-button'>
                  <img src={Down} alt="Down Arrow" />
                </button>
                <div
                  className="category-buttons"
                  onClick={() => handleCategoryClick(doc.name)}
                >
                  {doc.name}
                </div>
                <button onClick={() => handleDelete(doc.name)} className='delete-button'>
                  <img src={Delete} alt='delete' />
                </button>
              </div>
            ))}
            <Addcat path="MenuData" />
          </ul>
          <button onClick={handleSaveChanges} className='headbutton'>Save Changes</button>
        </div>
  
        <div className="admin-panel">
          <div className="selected-category">
            {selectedCategory ? (
              <div>
                {selectedCategory}
                <div className="menu-line"></div> 
              </div>
            ) : (
              <div className="no-category-message">
                No categories selected, select one to begin or start by creating a category
              </div>
            )}
          </div>
          {selectedCategory && <Adminchildren path={`MenuData/${selectedCategory}/children`} />}
        </div> 
      </div>

      <div className='return-menu'>
          <a href='/'>
            <img src={Preview} alt='go back' />
            <div>Go back</div>
          </a>
      </div>
    </>
  );
}  

export default Admin;
